export const fr = {
  ja: 'Oui',
  nein: 'Non',
  new: 'Créer',
  speichern: 'Sauvegarder',
  vorschau: 'Aperçu',
  allgemein: 'Général',
  disziplin: 'Discipline',
  disziplinen: 'Disciplines',
  verantwortlich: 'Responsable',
  status: 'Statut',
  schliessen: 'fermer',
  unbekannte_frage: 'Question inconnue:',
  juryrapport_abschliessen: 'Fermer le rapport du jury',
  juryrapport: 'Rapport du Jury',
  juryrapporte: 'Rapports du Jury',

  veranstaltungen: 'Manifestation',
  veranstaltungen_nicht_ohne_rechte: "Pour enregistrer une manifestation, vouz devez disposer des droits d'utilisateur appropriés.",
  veranstaltungen_rechte_liste: 'Vous avez déjà demandé les droits suivants:',
  veranstaltungen_rechte_mehr_beantragen: 'Demander des droits supplémentaires en utilisant le formulaire ci-dessous:',
  veranstaltungen_rechte_beantragen: 'Demandez les droits en utilisant le formulaire ci-dessous:',
  veranstaltungen_rechte_warten: 'Veuillez attendre que vos droits soient activés pour les organisateurs.',
  akzeptiert: 'Accepté',
  kommentar: 'Remarques',
  rechte_anfordern: 'envoyer',

  datum: 'Date',
  ort: 'Lieu',
  typ: 'Typ',
  modus: 'Modus',
  vorgesehene_pruefungen: 'Épreuves prévues',
  regionalverband: 'Associations Régionales',
  regionalverband_gekuerzt: 'AR',
  sektion: 'Section',
  tag: 'Jour',
  anlass: 'Évènement',
  anlaesse: 'Évènements',
  keine_anlaesse: "Nous n'allons pas organiser des épreuves de championnat des associations cantonales ou de la fédération régionale",
  hat_stilpruefungen: "Est-ce que des épreuves de style pour l'obtention de la licence R sont prévues?",
  stilpruefungen: "Épreuves de style pour l'obtention de la licence R",
  anzahl_b100_stilpruefungen: 'Nombre des épreuves de style B100',
  anzahl_p95_stilpruefungen: 'Nombre des épreuves de style P95',
  zusatzinfos_anlaesse: 'Évènements details',
  durchfuehrungstage: "Jours d'exécution",
  veranstalter_ist_verbandsmitglied: 'Membre Swiss Equestrian',
  mitglied: 'Membre',
  nichtmitglied: 'Non-Membre',
  aktiv: 'Active',
  inaktiv: 'Inactive',
  sektion_rayon: 'Section / Rayon',
  partner_id: 'ID Partenaire',
  alle: 'Tout',
  filter: 'Filtrer',
  veranstaltung_anmelden: 'Nouvelle Manifestation',
  nennschluss: 'Délais',
  nennphase_start: 'Phase d’engagement dès le',
  nachnennphase_start: 'Phase d’engagement ultérieur dès le',
  nennphase_ab_21_uhr: '21:00',
  nennvariante_nennschluss: 'Délais',
  nennvariante_nennphase: 'Phase nominale',
  id: 'ID',
  vorname_name: 'Prénom Nom',
  rolle: 'Rôle',
  benutzer: 'Utilisateur',
  aktuar: 'Actuaire',

  anmeldung_neue_veranstaltung: 'Saisir nouvelle manifestations',
  veranstalter: 'Organisateur',
  sprache: 'Langue',
  rv_anlaesse: 'Évènement AR',
  plz: 'NP',
  kanton: 'Canton',
  plz_ort_kanton: 'NP - Lieu - Canton',
  website: 'Site internet',
  von: 'de',
  bis: 'à',
  ok_praesident_in: 'Président/e du CO',
  oas_verantwortliche_r: 'Responsable OAS',
  ausschreibungen_kontaktdaten: 'Tél./Fax proposition',
  nennschluss_definitiv: 'Délais',
  bulletin: 'Bulletin',
  ausschreibungs_ergaenzungen: 'Remarques et complements épreuves',

  access_key: 'Access-Key',
  im_nennsystem_aktiviert: "Système d'engagement?",
  bietet_stallungen_an: 'Offres écuries',
  ok_praesident: 'Président/e du CO',
  ok_sekretaer: 'Secrétaire CO',
  oas_verantwortlicher: 'Responsable OAS',

  veranstaltungsdaten: "Dates de l'événement",
  ausschreibung: 'Propositions',
  ausschreibung_starten: 'Commencer la proposition',
  weitere_informationen: "Plus d'informations",
  konto_infos: "Compte / Plus d'informations",
  nennungen: 'Engagements',
  dokumente: 'Documents',

  stallungen: 'Écuries',
  kopfdaten: 'En-tête',
  beteiligungen: 'Personnes',
  pruefungen: 'Épreuves',
  fussdaten: 'Pied de page',

  berechnungsschema: 'Schéma de calcul',
  betrag: 'Montant',
  total_betrag: 'Total Montant',
  stallungsangebot_aktiv: 'Écuries actives',
  erste_titelzeile: '1er titre',
  zweite_titelzeile: '2ème titre',
  dritte_titelzeile: '3ème titre',

  person: 'Personne',
  funktion: 'Fonction',
  telefonnummer: 'Téléphone',
  nummer: 'Numéro',
  nummer_nr: 'No',
  kategorie: 'Catégorie',
  wertung: 'Barème',
  wertung_text: 'Barème',
  springen_anzahl_stufen: 'Degré',
  equipe: 'Equipe',
  equipe_anzahl_nennungen: 'Equipe',
  lizenzen: 'Licences',
  nenngeld: 'Fin.',
  nenngeld_ng: 'NG',
  nenngeld2: 'Fin. ultérieurs',
  nachnennphase_nenngeld_erhoehung: 'Augmentation Fin. ultérieurs',
  preise: 'Prix',
  bemerkungen: 'Remarques',
  bemerkung_rayon: 'Remarque / Rayon',
  email: 'E-Mail',
  telefon_m: 'Téléphone M',
  telefon_p: 'Téléphone P',
  telefon_g: 'Téléphone G',
  n_reiter_nur_4_und_5_jaehrige_pferde: 'Cavaliers N uniquement chevaux de 4 et 5 ans',
  r_reiter_nur_4_und_5_jaehrige_pferde: 'Cavaliers R uniquement chevaux de 4 et 5 ans',

  brevet: 'Brevet',
  regional: 'Régional',
  national: 'National',
  paar: 'Paar',
  pruefung_hat_gwp_beschraenkungen: 'Épreuve avec restrictions GWP',
  gewinnpunkte_pferd_brevet_minimal: "Cheval Brevet min.",
  gewinnpunkte_pferd_brevet_maximal: "Cheval Brevet max.",
  gewinnpunkte_reiter_brevet_minimal: "Reiter Brevet min.",
  gewinnpunkte_reiter_brevet_maximal: "Reiter Brevet max.",
  gewinnpunkte_paar_brevet_minimal: "Paar Brevet min.",
  gewinnpunkte_paar_brevet_maximal: "Paar Brevet max.",
  gewinnpunkte_pferd_regional_minimal: "Cheval regional min.",
  gewinnpunkte_pferd_regional_maximal: "Cheval regional max.",
  gewinnpunkte_reiter_regional_minimal: "Reiter regional min.",
  gewinnpunkte_reiter_regional_maximal: "Reiter regional max.",
  gewinnpunkte_paar_regional_minimal: "Paar regional min.",
  gewinnpunkte_paar_regional_maximal: "Paar regional max.",
  gewinnpunkte_pferd_national_minimal: "Cheval national min.",
  gewinnpunkte_pferd_national_maximal: "Cheval national max.",
  gewinnpunkte_reiter_national_minimal: "Reiter national min.",
  gewinnpunkte_reiter_national_maximal: "Reiter national max.",
  gewinnpunkte_paar_national_minimal: "Paar national min.",
  gewinnpunkte_paar_national_maximal: "Paar national max.",

  neue_titelzeile: 'Nouveau titre',
  neue_pruefung: 'Nouvelle épreuve',
  titelzeile_erstellen: 'Nouveau titre',
  pruefung_erstellen: 'Nouvelle épreuve',
  titelzeile_bearbeiten: 'Editer titre',
  pruefung_bearbeiten: 'Editer épreuves',

  cancel: 'Annuler',
  save: 'Sauvegarder',
  create: 'Ajouter',
  add: 'Ajouter',
  edit: 'Editer',
  delete: 'Effacer',
  reset: 'Annuler',
  copy: 'Copier',
  bestaetigen: 'Confirmer',
  show: 'Montrer',
  accept: 'Accepter',

  veranstaltung_kopieren_titel: 'Copier manifestation',
  veranstaltung_kopieren_beschreibung: "Si vous avez déjà établi des propositions, vous pouvez reprendre toutes les données. Choisissez simplement la manifestation et   débutez la procédure en cliquant sur 'Copier'",
  veranstaltung_kopieren_button: 'Copier',
  veranstaltung_nicht_kopieren_titel: 'Nouvelle manifestation',
  veranstaltung_nicht_kopieren_beschreibung: "Est-ce votre première proposition? N'avez-vous pas de proposition avec des données correspondantes à la manifestation actuelle? Alors saisissez toutes les informations das notre système de saisie online, en cliquant simplement sur 'Saisir nouvelle manifestation'Sie können eine neue Veranstaltung erstellen. Weiter geht es mit einem Klick auf 'Erfassung starten'",
  veranstaltung_nicht_kopieren_button: 'Saisir nouvelle manifestation',

  ausschreibung_kopieren_titel: 'Copier proposition',
  ausschreibung_kopieren_beschreibung: "Si vous avez déjà établi des propositions, vous pouvez reprendre toutes les données. Choisissez simplement la manifestation et débutez la procédure en cliquant sur 'Copier'",
  ausschreibung_kopieren_button: 'Copier',
  ausschreibung_nicht_kopieren_titel: 'Saisir proposition',
  ausschreibung_nicht_kopieren_beschreibung: "Est-ce votre première proposition? N'avez-vous pas de proposition avec des données correspondantes à la manifestation actuelle? Alors saisissez toutes les informations das notre système de saisie online, en cliquant simplement sur 'Saisir proposition'",
  ausschreibung_nicht_kopieren_button: 'Saisir proposition',

  titel: 'Titre',
  index: 'Ordre',
  inhalt: 'Contenu',
  neuer_fusstext: 'Rajouter texte en bas de page',
  neue_beteiligung: 'Rajouter nouvelle personne',
  sichtbar: 'Visible',
  name: 'Nom',
  nr: 'No',
  details: 'Details',
  nennvariante: "Système d'engagement",
  ng: 'Fin.',
  software: 'Logiciel',
  keine: 'Aucun',
  accept_sw_terms: "J'ai pris note de la convention entre la FSSE et l'organisateur et je l'accepte",
  please_accept_sw_terms: "Veuillez lire et accepter la convention entre la FSSE et l'organisateur",
  startkartenbestellung: 'Commande de cartes de start',
  anzahl_bestellte_startkarten: 'Nombre',
  startkarten_versandadresse: "Adresse d'envoi",
  kontodaten: 'Compte',
  zahlungsinformationen: 'Informations de paiement',
  kontoart: 'Banque / Postal',
  name_bank: 'Nom / Maison',
  iban: 'IBAN',
  kontoinhaber: 'Titulaire du compte',
  name_firma: 'Nom / Maison',
  strasse: 'RUE',
  zusatz: 'Complément',
  land: 'Pays',
  post: 'Postal',
  bank: 'Banque',
  liechtenstein: 'LIE - Liechtenstein',
  schweiz: 'SUI - Suisse',

  stallung_pauschal: 'Forfait',
  stallung_pauschal_pro_tag: 'Forfait par jours',
  stallung_variabel: 'Variable',
  stallung_variabel_pro_tag: 'Variable par jour',

  stallungsangebot_erfassen: 'Saisir écuries',
  stallungsangebot_zurueckziehen: "Retirer l'offre d'écurie",

  total_nenngeld: "Total frais d'inscription",
  total_nennungen: 'Total des inscriptions',
  zaehlbare_nennungen: 'Dénombrable engagements',
  hc_abgem_falschn_zurueckgew: 'HC / Désinscriptions / Erroné / Refusé',
  max_nennungen: "Max. d'inscriptions",
  ons_status: 'ONS-Status',
  erstellt_modifiziert: 'Créé / Modifié',
  gruppenname: 'Nom du group',
  longenfuehrer: 'Longeur',
  telefon: 'Téléphone',
  pferd_e: 'Cheval',
  lizenz_nr: 'No. licence / brevet',
  reiter_fahrer: 'Cavalier / Meneur',
  nennungen_bis_nennschluss: 'Engagements au délai',
  total_bis_nennschluss: "Total au délai d'engagement",
  nennungen_nachnennphase: 'Engagements phase ultérieur',
  total_nachnennphase: 'Totale phase ultérieur',
  pruefung_nr: 'Epreuve n°',
  veranstaltung: 'Manifestation',
  nennungen_per: "Engagement jusqu'au",
  anzahl_hc: 'Nombre de HC',
  abmeldungen: 'Nombre de désinscriptions',
  falschnennungen: 'Falschnennungen',
  zurueckgewiesene: 'Nombre de Engagements refusé',
  total_zaehlbare_nennungen: 'Dénombrable Engagements',
  anzahl_startplaetze_max: "Max. d'inscriptions",
  nachnennphase: "phase d'engagement ultérieure",
  anzahl_veranstalter_startplaetze: 'Nombre places pour organisateur',
  nennungen_auf_pruefungen: 'Inscriptions aux épreuves',
  anzahl_nennungen: 'Nombre des inscriptions',
  status_veranstaltung: "Statut de l'événement",
  status_nennung: "Statut de l’inscription",
  fei_event_type_code: 'Event',
  fei_competition_code: 'Competition',
  fei_event_type_level: 'Level',
  bedding: 'Bedding',
  bemerkungen_pferd: "Remarques Cheval (va directement à l'organisateur)",
  bemerkungen_reiter: 'Remarques Cavalier',
  bemerkungen_athlet: "Remarque Athlète (va directement à l'organisateur)",
  geht_direkt_an_veranstalter_info: 'Va directement à l’organisateur',
  slot: 'Slot',
  lizenz_brev_nr: 'Numéro de licence / brevet',
  fei_lizenz: 'Licence Fei',
  verein: 'Club',
  pass_nr: 'Numéro de passeport',
  fei_pass_nr: 'Numéro de FEI passeport',
  pferde_name: 'Nom du cheval',
  signalement: 'Signalement',
  besitzer: 'Propriétaire',
  pferd_hinzufuegen: 'Ajouter un cheval',
  person_hinzufuegen: 'Ajouter une personne',
  wirklich_loeschen: 'Voulez-vous vraiment effacer?',
  veranstaltungsort: 'Lieu de la manifestation',
  event_datum: 'Date de la manifestation',
  athletes: 'Athlètes',
  reiter: 'Cavalier',

  nachnennphase_starten_pruefung_text: "Voulez-vous lancer la phase ultérieur pour l'épreuve %{nummer} de %{datum} - %{kategorie}?",
  nachnennphase_stoppen_pruefung_text: "Voulez-vous arrêter la phase ultérieur pour l'épreuve %{nummer} de %{datum} - %{kategorie}?",

  geplante_teilpruefungen: 'Essais partiel prévu',
  geplante_teilpruefungen_warnung: 'Ne sélectionner que des essais partiels, qui sont vraiment effectués!',

  gespann: 'Attelage',
  gespanne_fuer_fahren: 'Attelage',

  lizenzierung_gruppe: 'Licences',
  lizenzierung_gruppe_text: 'Licences',
  dressur_teilnehmer: 'Participants',
  dressur_rechteck: 'Carré',
  fahren_nur_pony: 'Poney seulement',
  fahren_nur_pferde: 'Cheval seulement',
  pony: 'Poney',
  pferd: 'Cheval',
  fuer_pferde: 'Pour cheval',
  fuer_ponies: 'Pour poney',

  concours_complet_dressur: 'Dressage',
  concours_complet_springen: 'Saut',
  concours_complet_cross: 'Cross',

  fahren_dressur: 'Dressage',
  fahren_gelaende: 'Terrain',
  fahren_hindernis: 'Maniabilité',
  fahren_derby: 'Derby',
  fahren_multitest: 'Multitest',
  fahren_freier_text: 'Libre',

  concours_complet_dressur_aktiv: 'Dressage',
  concours_complet_springen_aktiv: 'Saut',
  concours_complet_cross_aktiv: 'Cross',

  fahren_dressur_aktiv: 'Dressage',
  fahren_gelaende_aktiv: 'Gelände',
  fahren_hindernis_aktiv: 'Maniabilité',
  fahren_derby_aktiv: 'Derby',
  fahren_multitest_aktiv: 'Multitest',
  fahren_freier_text_aktiv: 'Libre',

  fahren_gespanne_1_aktiv: 'Attel.1',
  fahren_gespanne_2_aktiv: 'Attel.2',
  fahren_gespanne_4_aktiv: 'Attel.4',
  fahren_gespanne_tandem_aktiv: 'Tandem',

  fahren_gespanne_1_label: '1',
  fahren_gespanne_2_label: '2',
  fahren_gespanne_4_label: '4',
  fahren_gespanne_tandem_label: 'tandem',

  endurance_distanz: 'Distanz',
  endurance_teilnehmer: 'Teilnehmer',
  endurance_schlaufen: 'Schlaufen/km',

  ons_status_keinen: 'Aucun',
  ons_status_nennphase: "Phase d'engagement",
  ons_status_nennschluss: 'Engagements clôturés',
  ons_status_nachnennphase: "Phase d'engagement ultérieure",
  ons_status_abgeschlossen: 'Terminé',
  ons_status_nachnennphase_geplant: 'Phase d’engagement ultérieur planifié',

  veranstalter_startplaetze: 'Places pour organisateur',
  nachnennphase_starten: 'Début de la phase ultérieur',
  nachnennphase_stoppen: 'Arrêt de la phase ultérieur',
  anzahl_nennungen_nachnennphase: "Max. d'inscriptions",
  nenngeld_nachnennphase: 'Fin. ultérieurs',
  kommentar_nachnennphase: 'Remarque ultérieurs (optional)',

  pruefung_warnung_dressur_1: 'Veuillez prendre note des points suivants et les vérifier soigneusement:',
  pruefung_warnung_dressur_2: 'Il n’est pas permis d’organiser des épreuves jumelées pour les cavaliers B, R et N (Dressage). Des épreuves jumelées pour détenteurs du brevet (B) et de la licence R ainsi que pour détenteurs de la licence R et N sont autorisées, avec éventuellement des restrictions de la licence (par ex. somme de points, âge des chevaux, classements, etc.)',
  pruefung_warnung_dressur_3: 'Les dimensions du carré ne doivent être indiquées dans la proposition que si cela ne ressort pas du programme de dressage.',
  pruefung_warnung_dressur_4: 'Le nombre de partants ne doit être indiqué dans la proposition que si cela diffère du Règlement de Dressage.',
  pruefung_warnung_dressur_5: 'Sélectionnez correctement les licences et ne copiez pas celles de l’année précédente sans contrôler. Le nom du Brevet est BRK (Brevet d’équitation combiné) et BRD (Brevet d’équitation de dressage) et non plus RB (Brevet d’équitation) comme cela était le cas auparavant.',
  pruefung_warnung_dressur_6: 'Les épreuves "Libre à la Carte" sont des épreuves spéciales (mode "S").',
  pruefung_warnung_dressur_7: 'Les épreuves avec la restriction « exclusivement pour poneys », sont des épreuves officielles (mode "O").',
  pruefung_warnung_dressur_8: 'Les épreuves avec restrictions concernant les races de chevaux (Arabe, Baroque, Demi-sang, etc.) sont également considérées comme des épreuves officielles (mode "O").',
  pruefung_warnung_dressur_9: 'En revanche, les épreuves de loisirs / promotion des chevaux Franches-Montagnes et Haflinger sont des épreuves spéciales (mode "S").',
  pruefung_warnung_dressur_10: 'Les épreuves spéciales (SP/CD) ne peuvent être décrits que dans les remarques.',
  pruefung_warnung_dressur_11: 'Veuillez noter la phrase suivante en pied de page de la proposition :',
  pruefung_warnung_dressur_12: 'Les reprises de dressage doivent être montées selon l’état actuel (https://swiss-equestrian.ch/fr/Disciplines/Dressage/Programmes-de-dressage/Programmes-de-Dressage.html) et les numéros de tête sont obligatoires aux épreuves de dressage.',

  pruefung_warnung_springen_1: 'Veuillez prendre note des points suivants et les vérifier soigneusement:',
  pruefung_warnung_springen_2: 'Le barème A au chrono n’est pas autorisé dans les épreuves jusqu’à B85.',
  pruefung_warnung_springen_3: 'Si une épreuve avec des degrés / niveaux est annoncée, la case « Nombre de niveaux » doit être sélectionnée et les degrés / niveaux doivent être décrits dans les remarques.',
  pruefung_warnung_springen_4: 'Si une épreuve avec des équipes est annoncée, la case « Équipe » doit être sélectionnée et l’équipe doit être définie (équipe de 2, 3 ou 4). Dans le cas ou de différents degrés sont également proposés, la case « Nombre de niveaux » doit être sélectionnée et les degrés / niveaux doivent être décrits dans les remarques.',
  pruefung_warnung_springen_5: 'Les épreuves spéciales (SP/CS) ne peuvent être décrits que dans les remarques.',

  pruefung_warnung_ca_1: 'Veuillez prendre note des points suivants et les vérifier soigneusement:',
  pruefung_warnung_ca_2: 'En revanche, les épreuves de loisirs / promotion des chevaux Franches-Montagnes et Haflinger sont des épreuves spéciales (mode "S").',
  pruefung_warnung_ca_3: 'Toutes les autres épreuves sont des épreuves spéciales.',
  pruefung_warnung_ca_4: 'Les épreuves spéciales (SP/CA) ne peuvent être décrits que dans les remarques.',

  bilder_zu_klein_1: "L'image téléchargée n'est pas assez élevée pour garantir une bonne qualité d'impression.",
  bilder_zu_klein_n: "Les images téléchargées ne sont pas assez hautes pour garantir une bonne qualité d'impression.",

  veranstaltungs_dokumente: 'Document de la manifestation',
  software_dokumente: 'Logiciels',
  keine_dokumente: 'Aucun document disponible',
  erstellt_am: 'Etabli le',

  veranstalter_anzeigen: 'Visile',

  aktionen: 'Actions',

  melden: 'Annoncer',
  freigeben: 'Libérer',
  loeschen: 'Effacer',
  absagen: 'Annuler',
  begruendung: 'Motifs',
  datensatz_loeschen_text: "Voulez-vous vraiment supprimer l'enregistrement?",

  genehmigen_text: "Souhaitez-vous approuver la manifestation et l'envoyer à la FSSE?",
  alle_genehmigen_text: 'Souhaitez-vous approuver toutes les manifestations annoncées et les envoyer à la FSSE pour approbation?',
  alle_anlaesse_genehmigen_text: 'Voulez-vous rendre définitives toutes les manifestations provisoires ?',
  melden_text: "Souhaitez-vous annoncer la manifestation à l'Association régionale %{regionalverband}?",
  loeschen_text: 'Souhaitez-vous vraiment effacer la manifestation?',
  absagen_text: "Motif de l'annulation:",
  alle_genehmigen: 'Tout approuver',
  genehmigen: 'Approuver',
  export_xlsx: 'Exporter XLSX',

  zurueckweisen: 'Refuser',
  nennung_zurueckweisen: 'Refuser l‘inscription',
  zurueckweisen_falschnennung: 'Engagement erroné: payant – Finance d‘engagement (incl. taxes & redevances) ne doit pas être remboursée',
  zurueckweisen_zurueckgewiesen: 'Engagement refusé: non-payant – Engagements en surnombre – Finance d‘engagement (incl. taxes & redevances) doit être remboursée',
  zurueckweisen_grund: 'Remarque',
  zurueckweisungsgruende: 'Motifs de rejet',
  gemeldete_veranstaltung_zurueckweisen: 'Veuillez s.v.p. indiquer une raison pour le rejet.',

  freigeben_titel: 'Libérer la proposition pour contrôle',
  freigeben_frage: "Après la  transmission, l'organisateur ne peut plus apporter de corrections! Vous serez informé par e-mail, quand la FSSE aura  terminé le contrôle.",
  freigeben_funktionen: 'Fonctions possibles pour officiels par discipline',
  freigeben_parcours: 'Les constructeurs de parcours/parcours cross ont confirmé la participation à la manifestation.',
  freigeben_tierarzt: 'Le vétérinaire mentionné a confirmé la participation à la manifestation.',
  freigeben_jury_technik: 'Le Président de jury et/ou le Délégué technique a été intégré dans le processus de préparation de la proposition et a confirmé la participation à la manifestationcher.',
  freigeben_notiz: 'Notes pour la FSSE',
  freigeben_felder_fehlen: "Vous n'avez pas encore saisi toutes les données nécessaires à l'approbation. Veuillez confirmer toutes les questions.",
  freigeben_daten_fehlen: "Vous n'avez pas encore saisi toutes les données nécessaires à l'approbation. Les formulaires suivants ne contiennent toujours pas de données:",
  freigeben_software_fehlt: 'Logiciele',
  freigeben_konto_fehlt: 'Compte',
  freigeben_pruefungen_fehlen: 'Épreuves',
  freigeben_pruefungen_ohne_max_nennungen: "Max. d'inscriptions aux épreuves",

  changelog: 'Changelog',
  changelog_created: 'Engagement créé',
  changelog_updated: 'Modifié',
  changelog_reiterwechsel: 'Changement de cavalier',
  changelog_reiterwechsel_2: '%{reiter_alt} remplacer avec %{reiter_neu}',
  changelog_pferdewechsel: "Changement d'un cheval",
  changelog_pferdewechsel_2: '%{pferd_alt} remplacer avec %{pferd_neu}',
  changelog_zurueckgewiesen_mit_grund: 'Engagement Refusé',
  changelog_zurueckgewiesen_mit_grund_2: 'Remarque: %{grund}',
  changelog_zurueckgewiesen: 'Engagement Refusé',
  changelog_zurueckgewiesen_2: ' ',
  changelog_falschnennung_mit_grund: 'Engagement erroné',
  changelog_falschnennung_mit_grund_2: 'Remarque: %{grund}',
  changelog_falschnennung: 'Engagement erroné',
  changelog_falschnennung_2: ' ',
  changelog_abgemeldet: 'Engagement désinscrit',
  changelog_abgemeldet_2: ' ',

  pferde_angeben: 'Es müssen alle Pferde angeben werden, deren Impfung nicht in Ordnung war.',
  pferde_doppelt: 'Es wurde zweimal das selbe Pferd angegeben, was nicht erlaubt ist.',

  filtern: 'Filtrer',
  zuruecksetzen: 'Réinitialiser',
  nur_eigene: 'Seulement les miens',
  sportjahr: 'Année sportive',
  suche: 'Chercher',

  pferde: 'Chevaux',
  personen: 'Personnes',

  programm_ids: 'Programmes',
  programme: 'Programme',

  gwp_beschraenkungen_formatiert: "GWP",

  abmelden: 'Déconnecter',
};
