import { StreamActions } from "@hotwired/turbo"
import { toast } from "../global/toast"
import { isMobileApp } from "../global/utils"

StreamActions.notify = function() {
  const type = this.getAttribute("type")
  const message = this.getAttribute("message")

  showFlashMessage(type, message)
}

StreamActions.postfinance_light_box_oeffnen = function() {
  const postfinanceLightBoxUrl = this.getAttribute("lightbox_js_url");
  const script = document.createElement('script');
  script.src = postfinanceLightBoxUrl;
  script.onload = function () {
    if (window.LightboxCheckoutHandler == undefined) {
      alert("PostFinance ist momentan nicht erreichbar");
    } else {
      window.LightboxCheckoutHandler.startPayment(null, function(){
        alert("PostFinance ist momentan nicht erreichbar");
      });
    }
  }
  document.head.appendChild(script);
}

function showFlashMessage(type, message) {
  if (isMobileApp) {
    window.bridge.postJSToNative("showFlashMessage", { message: message, type: type })
  } else {
    toast(type, message)
  }
}
