import { Controller } from "@hotwired/stimulus";
// Dieser Controller kann für Elemente verwendet werden, die standardmässig
// nicht klickbar sind, wie z.B. <tr>

// Mittels `data-action="mousedown->clickable-element#clickStart mouseup->clickable-element#maybeOpenLink"`
// kann zudem bestimmt werden, dass ein Link erst geöffnet wird, wenn eine maximale Zeit gedrückt wurde.
// damit kann unterschieden werden ob es ein klick war oder ein drag

// Connects to data-controller="clickable-element"
export default class extends Controller {
  static values = {
    withCurrentParams: { type: Boolean, default: false },
    extraParams: { type: Object, default: {} },
    turboDisabled: { type: Boolean, default: false },
  };

  openLink(event) {
    // Wenn element ein Link oder Formular Submit Button ist -> Nichts machen
    if (event.target.nodeName === "A" || event.target.type === "submit") {
      return;
    }

    this.url = this.element.dataset.url;
    this.params = new URLSearchParams(window.location.search);
    const options = event.params.visitOptions || {};

    // Extra Parameter anhängen
    if (this.extraParamsValue) {
      const extraParams = new URLSearchParams(this.extraParamsValue);
      for (const [key, value] of extraParams) {
        this.params.set(key, value);
      }
    }

    // Parameter anhängen
    if (this.withCurrentParamsValue) {
      this.url = `${this.url}?${this.params}`;
    }

    // Link öffnen
    const shouldOpenInNewTab = event.metaKey || event.ctrlKey || event.shiftKey;
    if (shouldOpenInNewTab) {
      window.open(this.url, "_blank");
    } else if (this.turboDisabledValue) {
      location.href = this.url;
    } else {
      Turbo.visit(this.url, options);
    }
  }

  clickStart(event) {
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.startTime = new Date();
  }

  maybeOpenLink(event) {
    // Wenn element die Klasse "no-link" hat -> Nichts machen
    if (event.target.classList.contains("no-link")) {
      return;
    }

    //const secondsPressed = (new Date() - this.startTime) / 1000;
    const xDiff = Math.abs(event.clientX - this.startX);
    const yDiff = Math.abs(event.clientY - this.startY);

    if (xDiff < 5 && yDiff < 5) {
      this.openLink(event);
    }
  }
}
